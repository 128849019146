/* eslint-disable react/prop-types */
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import Section from "../components/Section";

function PostTemplate({
  data: {
    page: { cover, childMdx, related, name },
  },
}) {
  return (
    <Section
      className={`blog${name.toLowerCase().includes("about") ? " about" : ""}`}
      align="center"
    >
      <section>
        <MDXRenderer>{childMdx.body}</MDXRenderer>
      </section>
    </Section>
  );
}

export default PostTemplate;

export function Head() {
  const noTrack = process.env.GATSBY_STAGING === true ? (
    <meta name="robots" content="noindex, nofollow" />
  ) : (
    <meta />
  );
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <script
        type="text/javascript"
        src="data:text/javascript;base64,
LyogVGh1IEphbiAgNSAwNTo0MzowNCAyMDIzIGZvciB3d3cucHVyZXBvc3QuY28gKi8KKGZ1bmN0aW9uICh3LGQscyxvLGYsanMsZmpzKSB7d1snSlMtV2lkZ2V0J109bzt3W29dID0gd1tvXSB8fCBmdW5jdGlvbiAoKSB7ICh3W29dLnEgPSB3W29dLnEgfHwgW10pLnB1c2goYXJndW1lbnRzKSB9O2pzID0gZC5jcmVhdGVFbGVtZW50KHMpLCBmanMgPSBkLmdldEVsZW1lbnRzQnlUYWdOYW1lKHMpWzBdO2pzLmlkID0gbzsganMuc3JjID0gZjsganMuYXN5bmMgPSAxOyBmanMucGFyZW50Tm9kZS5pbnNlcnRCZWZvcmUoanMsIGZqcyk7fSh3aW5kb3csIGRvY3VtZW50LCAnc2NyaXB0JywgJ2FsbGknLCAnaHR0cHM6Ly9zdGF0aWMuYWxsaWFpLmNvbS93aWRnZXQvdjEuanMnKSk7YWxsaSgnaW5pdCcsICdzaXRlXzVLYzNKaTdZcExUNkhLN0UnKTthbGxpKCdvcHRpbWl6ZScsICdhbGwnKTs="
      />
      {noTrack}
    </>
  );
}

export const pageQuery = graphql`
  query Post($path: String!) {
    page: googleDocs(slug: { eq: $path }) {
      name
      cover {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      childMdx {
        body
      }
      related {
        name
        slug
      }
    }
  }
`;
